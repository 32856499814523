import { useCallback, useEffect, useRef } from 'react';

import { Box } from '@/components/shared/layout/box';
import { Image } from '@/components/shared/media/image';
import { generateSplashPageImageSources } from '@/components/shared/media/image/generate-sources';
import { ImageProps } from '@/components/shared/media/image/Image.props';
import { ContentWithAction } from '@/components/shared/my24/content-with-action';
import {
  clearBodyLockScroll,
  enableBodyLockScroll,
} from '@/components/shared/utility/lock-scroll';
import { styled } from '@/stitches.config';
import { IItem, IMy24Tracking } from '@/types/shared';

const StyledSplash = styled('section', {
  '--splash-mobile-width': 360,
  '--splash-mobile-height': 640,
  '--splash-mobile-gap-x': 16,
  '--splash-mobile-gap-y': 40,

  '--splash-tablet-width': 768,
  '--splash-tablet-height': 1024,
  '--splash-tablet-gap-x': 48,
  '--splash-tablet-gap-y': 64,

  '--splash-desktop-width': 1280,
  '--splash-desktop-height': 640,
  '--splash-desktop-gap-x': 104,
  '--splash-desktop-gap-y': 48,
  position: 'fixed',
  width: '$size-w-screen',
  height: '$size-h-screen',
  left: 0,
  top: 0,
  bc: '$black',
  zIndex: '$popup',
  '@supports (height: 100dvh)': {
    height: '100dvh',
  },
});

const SplashImageWrap = styled(Box, {
  aspectRatio: '9 / 16',
  '@lg': {
    aspectRatio: '16 / 9',
  },
  '@maxlg': {
    '@landscape': {
      aspectRatio: '16 / 9',
    },
  },
});

const SplashContent = styled(Box, {
  px: 'calc(var(--splash-mobile-gap-x) * 1px)',
  '@xs': {
    px: 'calc(var(--splash-tablet-gap-x) * 1px)',
  },
  '@lg': {
    px: 'calc(var(--splash-desktop-gap-x) / var(--splash-desktop-width) * 100%)',
  },
});

const SplashContentTop = styled(Box, {
  '@portrait': {
    top: `${(40 / 640) * 100 + 'vh'}`,
    justifyContent: 'center',
  },
  '@landscape': {
    top: `${(32 / 360) * 100 + 'vh'}`,
  },
  '@lg': {
    top: `${(80 / 800) * 100 + 'vh'}`,
  },
});

const SplashContentBottom = styled(Box, {
  '@portrait': {
    bottom: `${(40 / 640) * 100 + 'vh'}`,
  },
  '@landscape': {
    bottom: `${(32 / 360) * 100 + 'vh'}`,
  },
  '@lg': {
    bottom: `${(80 / 800) * 100 + 'vh'}`,
  },
  '& .content-with-action': {
    '& .content-buttons': {
      flexDirection: 'row',
      '@maxmd': {
        '@portrait': {
          flexDirection: 'column-reverse',
        },
      },
    },
  },
});

const imagePortrait = {
  aspectRatio: 'var(--splash-logo-m-width) / var(--splash-logo-m-height)',
  width: (192 / 360) * 100 + 'vw',
  maxWidth: 300,
};

const SplashLogoTop = styled('div', {
  position: 'relative',
  mx: '$space-0',
  '@portrait': {
    mx: 'auto',
  },
  '@lg': {
    aspectRatio: 'var(--splash-logo-w-width) / var(--splash-logo-w-height)',
    width: (224 / 1280) * 100 + 'vw',
    '@portrait': {
      ...imagePortrait,
    },
  },
  '@maxlg': {
    '@portrait': {
      ...imagePortrait,
    },
    '@landscape': {
      mx: '$space-0',
      aspectRatio: 'var(--splash-logo-w-width) / var(--splash-logo-w-height)',
      width: (224 / 1280) * 100 + 'vw',
    },
  },
  '& img': {
    '@lg': {
      '@landscape': {
        objectPosition: '0 0 !important',
      },
    },
    '@maxlg': {
      '@landscape': {
        objectPosition: '0 0 !important',
      },
    },
  },
});

export interface ISplashPage {
  isActive?: boolean;
  image?: ImageProps;
  imageDesktop?: ImageProps;
  bg?: ImageProps;
  bgDesktop?: ImageProps;
  logo?: ImageProps;
  logoDesktop?: ImageProps;
  headline: IItem;
  dataTrack?: IMy24Tracking;
  onClose?: () => void;
}

export const SplashPage = ({
  headline,
  bg,
  bgDesktop,
  image,
  imageDesktop,
  onClose,
  dataTrack = 'lcv-home',
}: ISplashPage) => {
  const SPLASH_REF = useRef<HTMLDivElement>(null);

  const disableScrolling = useCallback((e: PointerEvent | TouchEvent) => {
    if (e.cancelable) {
      e.preventDefault();
      e.stopPropagation();
    }
  }, []);

  const onCloseSplash = useCallback(() => {
    clearBodyLockScroll({ important: true });
    const splashElement = SPLASH_REF.current;

    if (!splashElement) return;

    splashElement.removeEventListener('pointermove', disableScrolling);
    splashElement.removeEventListener('touchmove', disableScrolling);

    onClose?.();
  }, [disableScrolling, onClose]);

  useEffect(() => {
    const splashElement = SPLASH_REF.current;
    if (!splashElement) return;

    enableBodyLockScroll({ important: true });

    splashElement.addEventListener('pointermove', disableScrolling, {
      passive: false,
    });
    splashElement.addEventListener('touchmove', disableScrolling, {
      passive: false,
    });
    return () => {
      splashElement.removeEventListener('pointermove', disableScrolling);
      splashElement.removeEventListener('touchmove', disableScrolling);
      clearBodyLockScroll({ important: true });
    };
  }, [disableScrolling]);

  return (
    <StyledSplash ref={SPLASH_REF} data-test="splash">
      <Box
        position="relative"
        width="full"
        height="full"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {!!bg?.src && !!bgDesktop?.src && (
          <SplashImageWrap
            position="relative"
            width="full"
            height="full"
            fontSize="0"
          >
            <Image
              priority
              src={bg?.src}
              alt={bg?.alt}
              width={bg?.width}
              height={bg?.height}
              layout="fill"
              sources={generateSplashPageImageSources({
                mobileSrc: bg?.src,
                desktopSrc: bgDesktop?.src,
              })}
            />
          </SplashImageWrap>
        )}
        <SplashContent
          position="absolute"
          top="0"
          left="0"
          width="full"
          height="full"
        >
          <Box position="relative" width="full" height="full">
            {!!image?.src && !!imageDesktop?.src && (
              <SplashContentTop
                position="absolute"
                left="0"
                width="full"
                display="flex"
              >
                <SplashLogoTop
                  css={{
                    '--splash-logo-m-width': image?.width,
                    '--splash-logo-m-height': image?.height,
                    '--splash-logo-w-width': imageDesktop?.width,
                    '--splash-logo-w-height': imageDesktop?.height,
                  }}
                >
                  <Image
                    priority
                    src={image?.src}
                    alt={image?.alt}
                    width={image?.width}
                    height={image?.height}
                    layout="fill"
                    objectFit="contain"
                    useSrcSetPattern
                  />
                </SplashLogoTop>
              </SplashContentTop>
            )}
            {!!headline && (
              <SplashContentBottom position="absolute" left="0" width="full">
                <ContentWithAction
                  className="content-with-action"
                  headline={headline}
                  dataTest="splash"
                  dataTrack={dataTrack}
                  onClick={onCloseSplash}
                  splash
                />
              </SplashContentBottom>
            )}
          </Box>
        </SplashContent>
      </Box>
    </StyledSplash>
  );
};

SplashPage.displayName = 'SplashPage';

import dynamic from 'next/dynamic';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { MainModalProps } from '@/components/shared/overlays/modal';
import type { BaseModalProps } from '@/components/shared/overlays/modal/base-modal';
import type { GalleryModalProps } from '@/components/shared/overlays/modal/gallery-modal';
import type { ImageModalProps } from '@/components/shared/overlays/modal/image-modal';
import type { ProductModalProps } from '@/components/shared/overlays/modal/product-modal';
import type { VideoModalProps } from '@/components/shared/overlays/modal/video-modal';
import { clearBodyLockScroll } from '@/components/shared/utility/lock-scroll';

const Modal = dynamic(() => import('@/components/shared/overlays/modal'), {
  ssr: false,
});

interface ModalApi {
  api: {
    open: (data?: ModalConfig) => void;
    close: () => void;
    isOpen: boolean;
  };
  component: React.ReactNode;
}

type OmitType = 'open' | 'onOk' | 'onCancel';

type ModalProps =
  | ({ type: 'main' } & Omit<BaseModalProps, 'open'>)
  | ({ type: 'image' } & Omit<ImageModalProps, 'open'>)
  | ({ type: 'video' } & Omit<VideoModalProps, OmitType>)
  | ({ type: 'product' } & Omit<ProductModalProps, OmitType>)
  | ({ type: 'gallery' } & Omit<GalleryModalProps, OmitType>);

interface ModalConfig extends Omit<MainModalProps, 'open' | 'type'> {
  size?: BaseModalProps['size'];
  imageItems?: ImageModalProps['items'] | GalleryModalProps['items'];
  imageDetail?: ImageModalProps['detail'];
  activeSlideIndex?: number;
  youtubeID?: string;
  isYoutubeShorts?: boolean;
  url?: string;
}

export const useModal = (config?: ModalProps, forceClose = true): ModalApi => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [options, setOptions] = useState<ModalProps>({
    lockScroll: true,
    ...config,
  });

  useEffect(() => {
    if (!isOpen && options.lockScroll) {
      clearBodyLockScroll();
    }
  }, [isOpen, options.lockScroll]);

  const onOpenModal = (modalConfig: ModalConfig) => {
    if (modalConfig && Object.keys(modalConfig).length) {
      const inputConfig = {
        ...modalConfig,
        size: modalConfig.size ?? 'md',
        items: modalConfig.imageItems ?? [],
        detail: modalConfig.imageDetail ?? {},
      } as any;
      setOptions((prevState) => ({ ...prevState, ...inputConfig }));
    }
    setIsOpen(true);
  };

  const onSelfCloseModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onCloseModal = useCallback(() => {
    config?.onClose?.();
    if (!forceClose) return;
    setIsOpen(false);
  }, [config, forceClose]);

  const onOkModal = useCallback(() => {
    if (!config) return;
    const { type } = config;
    if (type === 'main' || type === 'image') {
      config?.onOk?.();
      if (!forceClose) return;
    }
    setIsOpen(false);
  }, [config, forceClose]);

  const onCancelModal = useCallback(() => {
    if (!config) return;
    const { type } = config;
    if (type === 'main' || type === 'image') {
      config?.onCancel?.();
      if (!forceClose) return;
    }
    setIsOpen(false);
  }, [config, forceClose]);

  const component = useMemo(() => {
    const { type } = options;
    if (!isOpen) return null;
    return type === 'main' || type === 'image' ? (
      <Modal
        {...options}
        open={isOpen}
        onClose={onCloseModal}
        onOk={onOkModal}
        onCancel={onCancelModal}
      />
    ) : (
      <Modal {...options} open={isOpen} onClose={onCloseModal} />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, isOpen]);

  const api = Object.freeze({
    open: onOpenModal,
    close: onSelfCloseModal,
    isOpen: isOpen,
  });

  return { api, component } as ModalApi;
};

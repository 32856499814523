import { MEDIA_QUERIES } from '@/components/shared/media/image/Image.constants';
import {
  ImageOptions,
  Source,
} from '@/components/shared/media/image/Image.props';

interface GenerateDesktopSourcesProps {
  desktopSrc?: string;
  desktopOptions?: ImageOptions;
}

interface GenerateMobileSourcesProps {
  mobileSrc?: string;
  mobileOptions?: ImageOptions;
}

interface GenerateSourcesProps
  extends GenerateDesktopSourcesProps,
    GenerateMobileSourcesProps {}

export const generateLargeDesktopSources = (
  src: GenerateDesktopSourcesProps
): Source[] => {
  const { desktopSrc, desktopOptions } = src;
  return [
    {
      srcset: desktopSrc!,
      media: MEDIA_QUERIES.DESKTOP_XL, // min-width: 1920
      options: { ...desktopOptions, width: 1920 },
      device: 'desktop',
    },
    {
      srcset: desktopSrc!,
      media: MEDIA_QUERIES.DESKTOP_LG, // minWidth: 1600, maxWidth: 1919
      options: { ...desktopOptions, width: 1600 },
      device: 'desktop',
    },
    {
      srcset: desktopSrc!,
      media: MEDIA_QUERIES.DESKTOP_MD, // minWidth: 1440, maxWidth: 1599
      options: { ...desktopOptions, width: 1440 },
      device: 'desktop',
    },
  ];
};

export const generateDesktopSources = (
  src: GenerateDesktopSourcesProps
): Source[] => {
  const { desktopSrc, desktopOptions } = src;
  return [
    {
      srcset: desktopSrc!,
      media: MEDIA_QUERIES.DESKTOP_SM,
      options: { ...desktopOptions, width: 1366 },
      device: 'desktop',
    },
    {
      srcset: desktopSrc!,
      media: MEDIA_QUERIES.TABLET_LG,
      options: { ...desktopOptions, width: 1280 },
      device: 'desktop',
    },
  ];
};
export const generateLargeDesktopAndDesktopImageLowResolutionSources = (
  src: GenerateDesktopSourcesProps
): Source[] => {
  const { desktopSrc, desktopOptions } = src;
  return [
    {
      srcset: desktopSrc!,
      media: MEDIA_QUERIES.DESKTOP_XL,
      options: { ...desktopOptions, width: 1600 },
      device: 'desktop',
    },
    {
      srcset: desktopSrc!,
      media: MEDIA_QUERIES.DESKTOP_LG,
      options: { ...desktopOptions, width: 1440 },
      device: 'desktop',
    },
    {
      srcset: desktopSrc!,
      media: MEDIA_QUERIES.DESKTOP_MD,
      options: { ...desktopOptions, width: 1280 },
      device: 'desktop',
    },
    {
      srcset: desktopSrc!,
      media: MEDIA_QUERIES.DESKTOP_SM,
      options: { ...desktopOptions, width: 1024 },
      device: 'desktop',
    },
    {
      srcset: desktopSrc!,
      media: MEDIA_QUERIES.TABLET_LG,
      options: { ...desktopOptions, width: 960 },
      device: 'desktop',
    },
  ];
};
export const generateDesktopAndIpadProSources = (
  src: GenerateSourcesProps
): Source[] => {
  const { desktopSrc, desktopOptions, mobileSrc, mobileOptions } = src;
  return [
    {
      srcset: desktopSrc!,
      media: MEDIA_QUERIES.DESKTOP_SM_LANDSCAPE, // minWidth: 1280, maxWidth: 1439, orientation: 'landscape',
      options: { ...desktopOptions, width: 1366 },
      device: 'desktop',
    },
    {
      srcset: desktopSrc!,
      media: MEDIA_QUERIES.TABLET_LG_LANDSCAPE, // minWidth: 1024, maxWidth: 1279, orientation: 'landscape'
      options: { ...desktopOptions, width: 1280 },
      device: 'desktop',
    },
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.TABLET_LG_PORTRAIT, // minWidth: 1024, maxWidth: 1439, orientation: 'portrait'
      options: { ...mobileOptions, width: 1280 },
      device: 'mobile',
    },
  ];
};

export const generateTabletSources = (
  src: GenerateMobileSourcesProps
): Source[] => {
  const { mobileSrc, mobileOptions } = src;
  return [
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.TABLET_MD,
      options: { ...mobileOptions, width: 828 },
      device: 'mobile',
    },
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.TABLET_SM,
      options: { ...mobileOptions, width: 640 },
      device: 'mobile',
    },
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.MOBILE_LG,
      options: { ...mobileOptions, width: 576 },
      device: 'mobile',
    },
  ];
};

export const generateTabletForPortraitAndLandscapeSources = (
  src: GenerateSourcesProps
): Source[] => {
  const { desktopSrc, desktopOptions, mobileSrc, mobileOptions } = src;
  return [
    {
      srcset: desktopSrc!,
      media: MEDIA_QUERIES.MOBILE_LG_LANDSCAPE, // minWidth: 480, maxWidth: 1023, orientation: 'landscape'
      options: { ...desktopOptions, width: 750 },
      device: 'desktop',
    },
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.TABLET_MD_PORTRAIT, // minWidth: 768, maxWidth: 1023, orientation: 'portrait'
      options: { ...mobileOptions, width: 828 },
      device: 'mobile',
    },
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.TABLET_SM_PORTRAIT, // minWidth: 576, maxWidth: 767, orientation: 'portrait'
      options: { ...mobileOptions, width: 640 },
      device: 'mobile',
    },
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.MOBILE_LG_PORTRAIT, // minWidth: 480, maxWidth: 575, orientation: 'portrait'
      options: { ...mobileOptions, width: 576 },
      device: 'mobile',
    },
  ];
};

export const generateMobileSources = (
  src: GenerateMobileSourcesProps
): Source[] => {
  const { mobileSrc, mobileOptions } = src;
  return [
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.MOBILE_MD, // minWidth: 390, maxWidth: 479
      options: { ...mobileOptions, width: 414 },
      device: 'mobile',
    },
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.MOBILE_SM, // minWidth: 321, maxWidth: 389
      options: { ...mobileOptions, width: 375 },
      device: 'mobile',
    },
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.MOBILE_XS, // maxWidth: 320
      options: { ...mobileOptions, width: 320 },
      device: 'mobile',
    },
  ];
};

export const generateMobileHiResSources = (
  src: GenerateMobileSourcesProps
): Source[] => {
  const { mobileSrc, mobileOptions } = src;

  return [
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.MOBILE_MD, // minWidth: 390, maxWidth: 479
      options: { ...mobileOptions, width: 480 },
      device: 'mobile',
    },
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.MOBILE_SM, // minWidth: 321, maxWidth: 389
      options: { ...mobileOptions, width: 390 },
      device: 'mobile',
    },
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.MOBILE_XS, // maxWidth: 320
      options: { ...mobileOptions, width: 320 },
      device: 'mobile',
    },
  ];
};

export const generateBannerImageSources = (
  src: GenerateSourcesProps
): Source[] => {
  return [
    ...generateLargeDesktopSources(src),
    ...generateDesktopAndIpadProSources(src),
    ...generateTabletSources(src),
    ...generateMobileSources(src),
  ];
};

export const generateSplashPageImageSources = (
  src: GenerateSourcesProps
): Source[] => {
  return [
    ...generateLargeDesktopSources(src),
    ...generateDesktopAndIpadProSources(src),
    ...generateTabletForPortraitAndLandscapeSources(src),
    ...generateMobileSources(src),
  ];
};

export const generateCommonImageSources = (
  src: GenerateSourcesProps
): Source[] => {
  return [
    ...generateLargeDesktopSources(src),
    ...generateDesktopSources(src),
    ...generateTabletSources(src),
    ...generateMobileSources(src),
  ];
};

export const generateLargeDesktopAndDesktopImageSources = (
  src: GenerateSourcesProps
): Source[] => {
  return [...generateLargeDesktopSources(src), ...generateDesktopSources(src)];
};

export const generateTabletAndMobileImageSources = (
  src: GenerateSourcesProps
): Source[] => {
  return [...generateTabletSources(src), ...generateMobileSources(src)];
};

export const generateMobileAndLargeMobileImageSources = (
  src: GenerateSourcesProps
): Source[] => {
  const { mobileSrc, mobileOptions } = src;
  return [
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.TABLET_SM,
      options: { ...mobileOptions, width: 640 },
      device: 'mobile',
    },
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.MOBILE_LG,
      options: { ...mobileOptions, width: 576 },
      device: 'mobile',
    },
    ...generateMobileSources(src),
  ];
};

export const generateLowResolutionSources = (
  src: GenerateSourcesProps
): Source[] => {
  const { desktopSrc, desktopOptions, mobileSrc, mobileOptions } = src;

  return [
    {
      srcset: desktopSrc!,
      media: MEDIA_QUERIES.DESKTOP_XL,
      options: { ...desktopOptions, width: 1600 },
      device: 'desktop',
    },
    {
      srcset: desktopSrc!,
      media: MEDIA_QUERIES.DESKTOP_LG,
      options: { ...desktopOptions, width: 1440 },
      device: 'desktop',
    },
    {
      srcset: desktopSrc!,
      media: MEDIA_QUERIES.DESKTOP_MD,
      options: { ...desktopOptions, width: 1280 },
      device: 'desktop',
    },
    {
      srcset: desktopSrc!,
      media: MEDIA_QUERIES.DESKTOP_SM,
      options: { ...desktopOptions, width: 1024 },
      device: 'desktop',
    },
    {
      srcset: desktopSrc!,
      media: MEDIA_QUERIES.TABLET_LG,
      options: { ...desktopOptions, width: 960 },
      device: 'desktop',
    },
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.TABLET_MD,
      options: { ...mobileOptions, width: 720 },
      device: 'mobile',
    },
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.TABLET_SM,
      options: { ...mobileOptions, width: 640 },
      device: 'mobile',
    },
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.MOBILE_LG,
      options: { ...mobileOptions, width: 480 },
      device: 'mobile',
    },
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.MOBILE_MD,
      options: { ...mobileOptions, width: 360 },
      device: 'mobile',
    },
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.MOBILE_SM,
      options: { ...mobileOptions, width: 320 },
      device: 'mobile',
    },
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.MOBILE_XS,
      options: { ...mobileOptions, width: 240 },
      device: 'mobile',
    },
  ];
};

export const generateLowResolutionAndIpadProSources = (
  src: GenerateSourcesProps
): Source[] => {
  const { desktopSrc, desktopOptions, mobileSrc, mobileOptions } = src;

  return [
    {
      srcset: desktopSrc!,
      media: MEDIA_QUERIES.DESKTOP_XL,
      options: { ...desktopOptions, width: 1600 },
      device: 'desktop',
    },
    {
      srcset: desktopSrc!,
      media: MEDIA_QUERIES.DESKTOP_LG,
      options: { ...desktopOptions, width: 1440 },
      device: 'desktop',
    },
    {
      srcset: desktopSrc!,
      media: MEDIA_QUERIES.DESKTOP_MD,
      options: { ...desktopOptions, width: 1280 },
      device: 'desktop',
    },
    {
      srcset: desktopSrc!,
      media: MEDIA_QUERIES.DESKTOP_SM_LANDSCAPE,
      options: { ...desktopOptions, width: 1024 },
      device: 'desktop',
    },
    {
      srcset: desktopSrc!,
      media: MEDIA_QUERIES.TABLET_LG_LANDSCAPE,
      options: { ...desktopOptions, width: 960 },
      device: 'desktop',
    },
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.TABLET_LG_PORTRAIT,
      options: { ...mobileOptions, width: 960 },
      device: 'mobile',
    },
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.TABLET_MD,
      options: { ...mobileOptions, width: 720 },
      device: 'mobile',
    },
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.TABLET_SM,
      options: { ...mobileOptions, width: 640 },
      device: 'mobile',
    },
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.MOBILE_LG,
      options: { ...mobileOptions, width: 480 },
      device: 'mobile',
    },
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.MOBILE_MD,
      options: { ...mobileOptions, width: 360 },
      device: 'mobile',
    },
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.MOBILE_SM,
      options: { ...mobileOptions, width: 320 },
      device: 'mobile',
    },
    {
      srcset: mobileSrc!,
      media: MEDIA_QUERIES.MOBILE_XS,
      options: { ...mobileOptions, width: 240 },
      device: 'mobile',
    },
  ];
};

import { memo, useEffect, useState } from 'react';

import { ButtonIcon } from '@/components/shared/buttons/button-icon';
import { Text } from '@/components/shared/element/text';
import { Box } from '@/components/shared/layout/box';
import { removeSpan, youtubeURL } from '@/components/shared/utility/format';
import { styled } from '@/stitches.config';
import type { IItem, IMy24Tracking } from '@/types/shared';

const YoutubeWrapperStyled = styled(Box, {
  variants: {
    touch: {
      true: {
        '@lg': {
          mx: '$space-0',
          mb: '$space-6',
        },
      },
      false: {
        '@lg': {
          display: 'none',
        },
      },
    },
  },
  defaultVariants: {
    touch: false,
  },
});

interface YoutubeButtonProps {
  item?: IItem;
  onClick?: () => void;
  dataTest?: string;
  dataTrack?: IMy24Tracking;
}

export const YoutubeButton = memo(
  ({ onClick, item, dataTrack, dataTest }: YoutubeButtonProps) => {
    const [isTouchDevice, setIsTouchDevice] = useState<boolean | undefined>(
      undefined
    );

    useEffect(() => {
      const mediaQuery = window.matchMedia('(pointer: coarse)');
      setIsTouchDevice(mediaQuery.matches);
    }, []);
    return (
      <YoutubeWrapperStyled
        position="relative"
        pointerEvents="auto"
        cursor="pointer"
        width="max"
        marginX="auto"
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap="4"
        onClick={onClick}
        touch={isTouchDevice}
      >
        <ButtonIcon
          border={item?.youtube?.border}
          icon={item?.youtube?.icon}
          size={item?.youtube?.size}
          isCircle
          variant="button-icon-color-white"
          tracking={{
            dataTrack: dataTrack as string,
            dataTrackSection: dataTest,
            dataTrackText: item?.youtube?.label,
            dataTrackValue: removeSpan(item?.title as string),
            dataTrackUrl: youtubeURL(item?.youtube?.url as string),
          }}
          test={{
            dataTest: `${dataTest}_videoButton`,
          }}
        />
        <Text font="bold" size="text-base" variant="text-white">
          {item?.youtube?.label}
        </Text>
      </YoutubeWrapperStyled>
    );
  }
);

YoutubeButton.displayName = 'YoutubeButton';

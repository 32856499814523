import type { CSS } from '@stitches/react';

import { keyframes, styled } from '@/stitches.config';

export const animateProgressBar = keyframes({
  '0%': { transform: 'translateX(-100%) translateZ(0)' },
  '100%': { transform: 'translateX(0) translateZ(0)' },
});

const StyledPaginationBar = styled('div', {
  position: 'relative',
  width: '100%',
  pointerEvents: 'none',
  zIndex: 1,
  fontSize: 0,
  '& .swiper-pagination': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: '$space-4',
    fontSize: 0,
    height: '$size-2',
    '@lg': {
      columnGap: '$space-6',
    },
  },
  '& .swiper-pagination-bullet': {
    position: 'relative',
    flexGrow: 1,
    flexBasis: '$size-2',
    maxWidth: '$size-2',
    width: '$size-2',
    height: '100%',
    borderRadius: '$rounded-1',
    cursor: 'pointer',
    pointerEvents: 'all',
    transition:
      'flex-basis 0.4s var(--transition-easing, ease), max-width 0.4s var(--transition-easing, ease), width 0.4s var(--transition-easing, ease)',
    transformStyle: 'preserve-3d',
    '@md': {
      borderRadius: '$rounded-1-5',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'rgba(0, 0, 0, 0)',
      width: '$size-6',
      height: '$size-6',
      '@lg': {
        width: '$size-8',
        height: '$size-8',
      },
    },
    '& span': {
      position: 'relative',
      display: 'block',
      height: 'inherit',
      width: 'inherit',
      borderRadius: 'inherit',
      overflow: 'hidden',
      transform: 'translateZ(0)',
      '&::before, &::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        height: 'inherit',
        borderRadius: 'inherit',
      },
      '&::before': {
        width: 'inherit',
        opacity: 0.3,
      },
      '&::after': {
        width: '$size-10',
        opacity: 1,
        transition: 'opacity 0.4s var(--transition-easing)',
      },
    },
    '&.swiper-pagination-bullet-active': {
      flexBasis: '$size-10',
      maxWidth: '$size-10',
      width: '$size-10',
      pointerEvent: 'none',
      cursor: 'default',
      '& span': {
        '&::after': {
          opacity: 1,
        },
      },
    },
  },
  variants: {
    justify: {
      start: {
        '& .swiper-pagination': {
          justifyContent: 'flex-start',
        },
      },
      center: {
        '& .swiper-pagination': {
          justifyContent: 'center',
        },
      },
    },
    variant: {
      red: {
        '& .swiper-pagination-bullet': {
          '& span': {
            '&::before': {
              backgroundColor: '$white',
            },
            '&::after': {
              backgroundColor: '$primary',
            },
          },
        },
      },
      white: {
        '& .swiper-pagination-bullet': {
          '& span': {
            '&::before': {
              backgroundColor: '$white',
            },
            '&::after': {
              backgroundColor: '$white',
            },
          },
        },
      },
      gray: {
        '& .swiper-pagination-bullet': {
          '& span': {
            '&::before': {
              backgroundColor: '$gray900',
            },
            '&::after': {
              backgroundColor: '$gray900',
            },
          },
        },
      },
    },
    animateBanner: {
      true: {
        '--duration': '8s',
        '& .swiper-pagination-bullet': {
          '& span::after': {
            opacity: 1,
            transform: 'translateX(-100%) translateZ(0)',
            willChange: 'transform',
          },
        },
      },
    },
    startAnimateBanner: {
      true: {
        '& .swiper-pagination-bullet.swiper-pagination-bullet-active': {
          '& span::after': {
            animationName: `${animateProgressBar}`,
            animationDuration: 'var(--duration, 8s)',
            animationTimingFunction: 'linear',
            animationFillMode: 'forwards',
            animationPlayState: 'running',
          },
        },
      },
    },
    pauseActiveBar: {
      true: {
        '& .swiper-pagination-bullet.swiper-pagination-bullet-active': {
          '& span::after': {
            animationPlayState: 'paused',
          },
        },
      },
    },
    isStatic: {
      true: {
        '& .swiper-pagination-bullet:not(.swiper-pagination-bullet-active)': {
          '& span::after': {
            opacity: 0,
          },
        },
      },
    },
  },
  defaultVariants: {
    variant: 'red',
  },
});

export interface PaginationBarProps
  extends React.ComponentProps<typeof StyledPaginationBar> {
  children?: React.ReactNode;
  css?: CSS;
  className?: string;
}

export const PaginationBar: React.FC<PaginationBarProps> = ({
  children,
  css,
  className,
  justify,
  variant = 'red',
  animateBanner,
  startAnimateBanner,
  pauseActiveBar,
  isStatic,
}: PaginationBarProps) => {
  return (
    <StyledPaginationBar
      css={css}
      className={className}
      justify={justify}
      variant={variant}
      animateBanner={animateBanner}
      startAnimateBanner={startAnimateBanner}
      pauseActiveBar={pauseActiveBar}
      isStatic={isStatic}
    >
      {children}
    </StyledPaginationBar>
  );
};
PaginationBar.displayName = 'PaginationBar';

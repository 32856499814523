import gsap from 'gsap';
import { memo, useEffect, useRef, useState } from 'react';

import { useMediaQuery } from '@/components/hooks/useMediaQuery';
import { Icon } from '@/components/shared/element/icons';
import { Box } from '@/components/shared/layout/box';
import { config, styled } from '@/stitches.config';
import { IIcon } from '@/types/shared';
import { ITest } from '@/types/tracking';

const CursorButtonStyled = styled(Box, {
  variants: {
    show: {
      true: {
        display: 'flex',
      },
      false: {
        display: 'none',
      },
    },
  },
  defaultVariants: {
    show: false,
  },
});

const OFFSET_CURSOR_BUTTON = 48; // width / 2

interface PlayerCursorButtonProps {
  icon?: IIcon;
  isActive?: boolean;
  handleClick?: () => void;
  areaRef?: React.RefObject<HTMLDivElement | null>;
  test?: ITest;
}

export const PlayerCursorButton = memo(
  ({
    areaRef,
    isActive = false,
    icon = 'play',
    handleClick,
    test,
  }: PlayerCursorButtonProps) => {
    const isTouchDevice = useMediaQuery('(pointer:coarse)');
    const isMobile = useMediaQuery(config.media.maxlg);
    const cursorRef = useRef<HTMLDivElement | null>(null);

    const [isShowCursorButton, setIsShowCursorButton] = useState<boolean>(
      !isMobile || false
    );

    useEffect(() => {
      if (isMobile) return;
      if (isTouchDevice || !cursorRef.current) {
        if (isShowCursorButton) {
          setIsShowCursorButton(false);
        }
        return;
      }

      const ctx = gsap.context(() => {
        gsap.set(cursorRef.current, {
          xPercent: -100,
          yPercent: -100,
        });

        if (!isActive) {
          setIsShowCursorButton(false);
          gsap.to(cursorRef?.current, {
            duration: 0.1,
            scale: 0,
            ease: 'none',
            overwrite: 'auto',
          });
          document.body.style.cursor = 'auto';
          return;
        }

        const targets = gsap.utils.toArray(cursorRef.current);
        const areaElement = areaRef?.current;
        const handleMouseMove = (event: MouseEvent) => {
          const target = event.target as HTMLElement;
          const { clientX, clientY } = event;

          const isCursorHidden: boolean =
            ['A', 'BUTTON', 'SPAN'].includes(target.tagName) ||
            !!target.closest('[data-hide-cursor="true"]');

          const isCursorInside = areaElement?.contains(target);

          const scale = isCursorHidden ? 0 : 1;
          const cursor = isCursorHidden ? 'auto' : 'none';

          gsap.to(targets, {
            display: isCursorHidden ? 'none' : 'flex',
            duration: 0.1,
            x: isCursorInside ? clientX + OFFSET_CURSOR_BUTTON : 0,
            y: isCursorInside ? clientY + OFFSET_CURSOR_BUTTON : 0,
            scale,
            ease: 'none',
            overwrite: 'auto',
          });
          document.body.style.cursor = cursor;
        };
        const handleMouseOver = (event: MouseEvent) => {
          const target = event.target as HTMLElement;
          if (areaRef?.current?.contains(target)) {
            return setIsShowCursorButton(true);
          }

          setIsShowCursorButton(false);
        };

        const handleMouseClick = (event: MouseEvent) => {
          const target = event.target as HTMLElement;
          if (
            target.closest('[data-hide-cursor="true"]') ||
            ['A', 'BUTTON', 'SPAN'].includes(target.tagName)
          ) {
            return;
          }

          handleClick?.();
        };

        const handleMouseLeave = () => {
          document.body.style.cursor = 'auto';
          gsap.to(cursorRef.current, {
            scale: 0,
            duration: 0.1,
          });
          setIsShowCursorButton(false);
        };

        if (!areaElement) return;

        areaElement.addEventListener('mousemove', handleMouseMove);
        areaElement.addEventListener('mouseover', handleMouseOver);
        areaElement.addEventListener('mouseleave', handleMouseLeave);
        areaElement.addEventListener('click', handleMouseClick);

        return () => {
          areaElement.removeEventListener('mousemove', handleMouseMove);
          areaElement.removeEventListener('mouseover', handleMouseOver);
          areaElement.removeEventListener('mouseleave', handleMouseLeave);
          areaElement.removeEventListener('click', handleMouseClick);
        };
      }, cursorRef.current);

      return () => ctx.revert();
    }, [
      areaRef,
      handleClick,
      isActive,
      isMobile,
      isShowCursorButton,
      isTouchDevice,
    ]);

    return (
      <>
        {!isMobile && (
          <CursorButtonStyled
            ref={cursorRef}
            show={!isTouchDevice || isShowCursorButton}
            backgroundColor="white"
            display="flex"
            position="fixed"
            alignItems="center"
            justifyContent="center"
            boxShadow="300"
            width={24}
            height={24}
            padding={2}
            top={0}
            left={0}
            zIndex={1}
            pointerEvents="none"
            rounded="full"
            test={test}
          >
            <Icon icon={icon} size="3xl" />
          </CursorButtonStyled>
        )}
      </>
    );
  }
);

PlayerCursorButton.displayName = 'PlayerCursorButton';
